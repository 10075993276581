import React from 'react';
import { Hyperlink, SVG } from 'digit.commons.ui-components';
import { INFORMATION_RENT_ARREARS_SUPPORT } from './links';

export const PAGE_TITLE_APP = 'Wiener Wohnen Mieter*innenportal';
export const CUSTOMER_SERVICE_NUMBER = '+43 5 75 75 3700';
export const TENANT_PORTAL_SERVICE_NUMBER = '+43 5 75 75 75';
export const LAUNDRY_CUSTOMER_NUMBER = '+43 5 75 75 800';
export const CUSTOMER_SERVICE_MAIL = 'post@wrw.wien.gv.at';
export const HAUSBETREUUNG_MAIL = 'natuerlichsicher@hausbetreuung-wien.info';

export const ADMIN_PANEL = {
  ribbonLabel: 'Admin',
  search: 'Suche',
  customerId: {
    label: 'Wiener Wohnen ID',
    description: '7 Ziffern, z.B. 5475986',
  },
  pin: {
    label: 'Wiener Wohnen PIN',
    description: '4 Stellen, z.B. F6Z3',
  },
};

export const BANK_INFO = {
  title: 'Bankverbindung Wiener Wohnen',
  paragraph:
    'Bitte beachten Sie: die Bankverbindung kann sich nach Mietobjekt unterscheiden. Zahlen Sie offene Beträge für dieses Mietobjekt nur auf folgendes Konto ein:',
  iBan: 'IBAN',
  bic: 'BIC',
  info: (vkont: string): JSX.Element => (
    <>
      Sollten Sie keinen Zahlschein haben, verwenden Sie ihre Vertragskontonummer {vkont} als Zahlungsreferenz. ACHTUNG:
      Jedes Mietobjekt hat ein eigenes Vertragskonto. Achten Sie bitte daher bei mehreren Zahlungen auf die richtige{' '}
      <em>Vertragskontonummer</em>. Alternativ können Sie auch einen neuen Zahlschein beantragen.
    </>
  ),
};

export const ERROR_BOUNDARY = {
  title: 'Oh nein!',
  errorText: 'Leider kann zur Zeit nicht auf Ihre Daten zugegriffen werden. Bitte versuchen Sie es später noch einmal.',
  description: (
    <>
      {' '}
      Bei dringenden Fragen oder Problemen wenden Sie sich bitte an unsere Service-Nummer unter{' '}
      <Hyperlink id="error-boundary-service-number" to={`tel: ${TENANT_PORTAL_SERVICE_NUMBER}`} customHref={true}>
        {TENANT_PORTAL_SERVICE_NUMBER}
      </Hyperlink>
      .
    </>
  ),
  errorTextAndDescription: null,
};

export const GENERAL_FAILURE = {
  title: 'Oh nein!',
  errorText: 'Das hat leider nicht geklappt. Bitte versuchen Sie es später noch einmal.',
  description: (
    <>
      Sollte der Fehler weiterhin bestehen, wenden Sie sich bitte an unseren Helpdesk unter{' '}
      <Hyperlink id="error-boundary-service-number" to={`tel: ${TENANT_PORTAL_SERVICE_NUMBER}`} customHref={true}>
        {TENANT_PORTAL_SERVICE_NUMBER}
      </Hyperlink>
      .
    </>
  ),
  errorTextAndDescription: null,
};

GENERAL_FAILURE.errorTextAndDescription = (
  <>
    <p>{GENERAL_FAILURE.errorText}</p>
    <p>{GENERAL_FAILURE.description}</p>
  </>
);

export const FOOTER = {
  instagram: 'Folgen Sie uns auf Instagram',
  facebook: 'Folgen Sie uns auf Facebook',
};

export const HEADER = {
  logout: 'Abmelden',
  login: 'Anmelden',
  myProfile: 'Profil',
  burgerMenu: 'Menü',
  burgerClose: 'Schließen',
  sr_menu: 'Hauptmenü',
  loggedInAs: 'Angemeldet als',
};

export const POPOVER = {
  loggedInAs: 'Angemeldet als',
};

export const RENTAL_ACCOUNT_ANNOUNCEMENT = {
  iconTitle: 'Erinnerung',
  title: 'Zahlungen sind fällig',
  description: 'Für folgende Mietobjekte sind noch Zahlungen fällig:',
  linkTitle: 'Zum Mietzinskonto',
};

export const COVID_SUPPORT_ANNOUNCEMENT = {
  iconTitle: 'Information',
  title: 'COVID-19 Wohnungssicherung',
  description: (
    <p className={'Announcement__description'}>
      <span className={'Announcement__span'}>
        <em>Sie können aufgrund von COVID 19 Ihre Miete nicht bezahlen?</em>
        {'\n'}
        Hier erfahren Sie, was Sie tun können, um Ihre Wohnung zu behalten!
      </span>{' '}
      {'\n'}
      <Hyperlink
        id={'announcement-support'}
        to={INFORMATION_RENT_ARREARS_SUPPORT}
        isExternal={true}
        iconAfter={SVG.external_link}
        className={'Announcement__description-link'}
      >
        Hilfe bei Mietzinsrückstand
      </Hyperlink>
      {'\n'}
    </p>
  ),
  linkTitle: 'Zum Mietzinskonto',
};

export const PIPE_ANNOUNCEMENT = {
  linkText: 'Mehr erfahren',
};
